import React from "react";
import Footer from "../components/footer";
import App from "../containers/App";
import { Card, CardGroup, Container, Row, Col, Button } from "react-bootstrap";
import "./concurso-publico-pss.css";

const concurso = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <p className="titulo">
        CONCURSO PÚBLICO E PROCESSO SELETIVO SIMPLIFICADO
      </p>
    </Container>
    <Container>
      <Card
        style={{
          marginRight: "50px",
          boxShadow: "0 0 0 0",
          border: "0 none",
          outline: "0",
        }}
        className="concurso-card"
      >
	  
        <Row>
          <p className="titulo">Editais</p>
		
		<hr />
		  <h3>Processo Seletivo Simplificado - Auxiliar de Docente</h3>
		  <br /><br /><br />
		  
		  <h5>PSS nº 146/01/2025:  Auxiliar de Docente (área Mecânica)</h5>
		  <a
            id="left0"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/editais/2025/146-01-2025-PSSAD-EDITALDEABERTURA.pdf"
          >
            - Edital de abertura - Auxiliar de Docente (área de atuação: Mecânica)
          </a>
		  <br />
		  <br />
		  <br />
		  
		  <hr />
		  <h3>Processo Seletivo Simplificado - Docente</h3>
		  <br /><br /><br />
		  
		  <h5>PSS nº 146/02/2025: Inglês para Logística II</h5>
		  <a
            id="left0"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/editais/2025/DOE 18-02-2025 - Edital de Abertura de Inscrições PSS 146-02-2025 disciplina Inglês II - Logística.pdf"
          >
            - Edital de abertura - Disciplina: Inglês para Logística II (02 horas-aula, período matutino, Curso Superior de Tecnologia em LOGÍSTICA)
          </a>
		  <br />
		  <br />
		  <br />
		  
		  <h5>PSS nº 146/01/2025: Inglês II</h5>
		  <a
            id="left0"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/editais/2025/DOE 18-02-2025 - Edital de Abertura de Inscrições PSS 146-01-2025 disciplina Inglês II - Manufatura Avançada.pdf"
          >
            - Edital de abertura - Disciplina: Inglês II (02 horas-aula, período matutino, Curso Superior de Tecnologia em MANUFATURA AVANÇADA)
          </a>
		  <br />
		  <br />
		  <br />
		  
		</Row>
		
		<br />
		<br />
		<br />
		<Row>
		  <hr />
		  <hr />
		  <p className="titulo">Formas de Contratação</p>
          <p id="subtitulo">Docentes e Auxiliares de Docente</p>
          <p id="espaco">
            A contratação de docentes pode ocorrer de duas formas: por processo
            seletivo, quando a contratação é feita por prazo determinado
            (contrata-se por 1 ano, podendo ser prorrogado por mais 1 ano) e por
            concurso público, quando a contratação é feita por tempo
            indeterminado.
          </p>
		  
          <p id="subtitulo">Funcionários de áreas administrativas</p>
          <p id="espaco">
            A contratação de funcionários de áreas administrativas é feita somente através de concurso público.{" "}
          </p>
		  
		  <hr />
          <p id="subtitulo">Informações</p>
          <p>
            Para detalhes sobre a abertura de concursos, o candidato deverá
            contatar a Unidade de interesse pessoalmente, pelos telefones
            indicados no site ou nas seguintes páginas:{" "}
          </p>
		  
          <p>Concursos para contratação de docentes e auxiliares de docente do Centro Paula Souza</p>
          <a
            id="left"
            target="blank"
            href="https://www.cps.sp.gov.br/concursopublico/"
          >
            https://www.cps.sp.gov.br/concursopublico/
          </a>
		  
          <p> Concursos públicos do Estado de São Paulo</p>
		  <a
            id="left"
            target="blank"
            href="http://www.concursopublico.sp.gov.br"
          >
            http://www.concursopublico.sp.gov.br
          </a>
		  
          <p> Diário Oficial do Estado de São Paulo</p>
          <a
			id="left"
			target="blank"
			href="https://doe.sp.gov.br/"
		  >
            https://doe.sp.gov.br/
          </a>
		  
          <p id="subtitulo">Deliberações</p>
          <p>
            Dispõe sobre norma para a realização de Concurso Público para o
            preenchimento de emprego público permanente de Professor do Ensino
            Superior das Faculdades de Tecnologia do Centro Estadual de Educação
            Tecnológica Paula Souza.
          </p>
          <a
            id="left"
            target="blank"
            href="http://fatecsjc.edu.br/downloads/arquivos/2015-1/2015.01.09_deliberao_ceeteps_009.pdf"
          >
            Deliberação CEETEPS nº 009 de 09/01/2015
          </a>
        </Row>
      </Card>
    </Container>
    <Footer />
  </App>
);

export default concurso;
