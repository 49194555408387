import React from "react";
import App from "../containers/App";
import Footer from "../components/footer";
import { Card, CardGroup, Container, Row, Col, Button } from "react-bootstrap";
import "./curso-manutencao-de-aeronaves.css";
import ReactYouTube from "../components/youtube/ReactYoutube";

const introducao = [
  {
    titulo: "Resumo",
    descricao:
      "O Tecnólogo em Manutenção de Aeronaves supervisiona a revisão e a manutenção de aviões. Atua na gestão, no planejamento e no controle dos serviços de manutenção de aeronaves e seus componentes. No planejamento de serviços, define a sequência das atividades de manutenção, otimiza as tarefas e controla os prazos de execução de cada etapa de trabalho. Orienta o técnico na execução das atividades de montagem e regulagem dos componentes das aeronaves. Zela pelo cumprimento das normas de segurança de voo. O mercado para essa profissão é promissor. A frota de jatos executivos cresceu nos últimos anos na América Latina, onde o Brasil é o principal representante. Além disso, a região de São José dos Campos concentra as principais empresas aeroespaciais do País.",
    extra: "Para visualizar uma breve apresentação do curso, CLIQUE AQUI.",
  },
  {
    titulo: "Onde este tecnólogo poderá trabalhar?",
    descricao:
      "Companhias aéreas, fabricantes de aeronaves, fabricantes de partes e componentes de aeronaves, centros de manutenção de aeronaves e componentes, empresas de aviação executiva, empresas operadoras e de manutenção de helicópteros, Forças Armadas e autoridade aeronáutica (ANAC). Como empreendedor, pode implantar sua própria oficina de reparo ou revisão de componentes para atuar como fornecedor de serviços para os centros de manutenção de aeronaves.",
    extra: "",
  },
];

const links = [
  {
    titulo: "Inscreva-se",
    subtitulo: "40 Vagas > Período da Noite> Duração de 06 semestres",
    link: "https://vestibular.fatec.sp.gov.br/",
    botao: "Vestibular Fatec",
  },
  {
    titulo: "Matriz curricular",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/cursos/mnt/2018.12.13_matriz_manutencao.pdf",
    botao: "Clique aqui para visualizar a matriz curricular e a carga horária",
  },
  {
    titulo: "Projeto Pedagógico",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/cursos/mnt/projeto_pedagogico_mnt_reestruturacao_2019-outubro_2018.pdf",
    botao: "Clique aqui para visualizar o projeto pedagógico do curso.",
  },
  {
    titulo: "Regulamento do Curso FATEC - ANAC",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/cursos/mnt/REGULAMENTO DO CURSO FATEC - Manutenção de Aeronaves - Emissão 08.docx",
    botao: "Clique aqui para visualizar o Regulamento",
  },
  {
    titulo: "Docentes",
    subtitulo: "",
    link: "/docentes-mnt",
    botao: "Clique aqui para visualizar a lista de docentes.",
  },
  {
    titulo: "Regulamento Geral dos Cursos de Graduação das Fatecs",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/arquivos/regulamento_geral_fatecs.pdf",
    botao: "Clique aqui para visualizar o Regulamento",
  },
  {
    titulo: "Regime Disciplinar Discente",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/arquivos/regime_disciplinar_discente.pdf",
    botao: "Clique aqui para visualizar o Regime Disciplinar Discente",
  },
];

const manut_aero = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col>
          <p className="manut-titulo">MANUTENÇÃO DE AERONAVES</p>
          <p className="manut-subtitulo">
            40 Vagas no período da Noite | Controle e Processos Industriais
          </p>
        </Col>
      </Row>
    </Container>

    <Container id="resumo" style={{ marginBottom: "50px" }}>
      <Row>
        <Col md={{ span: 12 }}>
          <CardGroup>
            {introducao.map((object, index) => {
              return (
                <Card style={{ marginRight: "50px" }}>
                  <Card.Body>
                    <Card.Title className="manut-card-titulo">
                      {object.titulo}
                    </Card.Title>
                    <Card.Text className="manut-card-descricao">
                      {object.descricao}
                      <br />
                      <a
                        id="fonte"
                        href="https://sjc.fatec.sp.gov.br/downloads/cursos/mnt/2021.02.09_apresentacao_manutencao_aero.pdf"
                        target="_blank" rel="noopener noreferrer"
                      >
                        {object.extra}
                      </a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              );
            })}
          </CardGroup>
        </Col>
      </Row>
    </Container>
	
    <Container style={{ display: "flex", justifyContent: "center" }}>
      <ReactYouTube videoId="d1vkNIYDVWA" />
      <br />
    </Container>
	<br /><br />
	
    <Container style={{ marginBottom: "50px", textAlign: "center" }}>
      <Row xs={1} md={2} className="g-4">
        {links.map((object, index) => {
          return (
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title className="manut-card-link-titulo">
                    {object.titulo}
                  </Card.Title>
                  <Card.Text className="manut-card-link-subtitulo">
                    {object.subtitulo}
                  </Card.Text>
                  <Button
                    className="manut-card-botao"
                    variant="outline-success"
                    href={object.link}
                    target="_blank"
                  >
                    {object.botao}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
	

	<Container style={{ marginBottom: "50px", textAlign: "justify" }}>
	  <hr />
        <Row>
          <p className="manut-subtitulo2">Núcleo Docente Estruturante</p>
		
		  <a
            className="manut-a"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/cursos/mnt/Portaria NDE - Manutencao Aeronaves.pdf"
          >
            Portaria
          </a><br />
		  
		  <a
			className="manut-a"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/cursos/mnt/Regulamento Interno NDE-FATEC-SJC Aprovado09dez24.pdf"
          >
            Regulamento Interno
          </a><br />
		  
		  <a
            className="manut-a"
            target="blank"
            href="https://sjc.fatec.sp.gov.br/downloads/cursos/mnt/AtaNDEMnA2025-1.pdf"
          >
            Ata NDE Manutenção 2025-1
          </a>
		  <br />
		  <br />
		  <br />
		</Row>
		
    </Container>

    <Footer />
  </App>
);

export default manut_aero;
