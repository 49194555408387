import React from "react";
import { Card, CardGroup, Container, Row, Col, Table, Image} from "react-bootstrap";
import Footer from "../components/footer";
import App from "../containers/App";
import "./centro-de-memoria.css";
import jessenvidal from "../components/images/jessen_vidal.jpg";

const centrodememoria = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col style={{ marginTop: "30px" }}>
          <p className="centrodememoria-titulo">Centro de Memória</p>
        </Col>
      </Row>
    </Container>
	
	<Container style={{ marginBottom: "50px", textAlign: "justify" }}>
		<Row>
			<Col md={{ span: 12 }}>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" href="#centro_memoria">Centro de Memória da Fatec de São José dos Campos “Professor Jessen Vidal”</a></p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" href="https://drive.google.com/file/d/1Q_qKN48JRKcLZPD3oFUzTXj9HWI9dD7t/view" target="_blank">Vídeo de apresentação do Centro de Memórias</a></p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" href="#jessen_vidal">Quem foi Jessen Vidal?</a></p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" href="#breve_historico">Um breve histórico</a></p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" href="#parque_tecnologico">O Parque Tecnológico</a></p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" target="_blank" href="https://drive.google.com/file/d/1kEx7Y6QIP42cGZsiFt9sT_MDRUpQJAFQ/view?usp=drive_link" rel="noopener noreferrer">Quem foi Cesare Lattes?</a></p>
					<p className="centrodememoria-p2">
						&emsp;&emsp;O endereço da FATEC SJC é: Avenida Cesare Monsueto Giulio Lattes, 1350. Você sabe quem foi Cesare Lattes??????
					</p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" target="_blank" href="https://drive.google.com/file/d/1FAzaTTTNyuhCLGSeIbCiYwuJUn4RVs78/view?usp=drive_link" rel="noopener noreferrer">Quem foi Paula Souza?</a></p>
					<p className="centrodememoria-p2">
						&emsp;&emsp;A Fatec SJC é uma instituição de ensino que pertence ao Centro Paula Souza. Você sabe que foi Paula Souza????
					</p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" target="_blank" href="https://drive.google.com/file/d/1i6rj4sdhCGR1HsvqI9UJUphCdsTW2DGo/view?usp=sharing" rel="noopener noreferrer">BAJA SAE Brasil</a></p>
					<p className="centrodememoria-p2">
					    &emsp;&emsp;A competição Baja SAE Brasil é realizada desde 2016 em São José dos Campos junto a Fatec SJC.
					</p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" target="_blank" href="https://drive.google.com/file/d/1IvwmL92dNqbF7_8pORLdNdn66B98-ovg/view?usp=drive_link" rel="noopener noreferrer">Entrevista com o Professor Antônio Wellington Sales Rios</a></p>
					<p className="centrodememoria-p2">
						&emsp;&emsp;O Prof. Wellington foi o nosso primeiro diretor. Quer saber mais sobre ele?
					</p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" target="_blank" href="https://drive.google.com/file/d/1SxTAxrzHkQ6EKTVCfhem2Oe9vtPuTuDW/view?usp=drive_link" rel="noopener noreferrer">Entrevista com o Professor Nilo Jeronimo Vieira</a></p>
					<p className="centrodememoria-p2">
						&emsp;&emsp;O Prof. Nilo foi o nosso primeiro funcionário. Quer saber mais sobre ele?
					</p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" target="_blank" href="https://drive.google.com/file/d/1L60aHN_IGIya8PS7fMvbJAEtyCD7DQ84/view?usp=drive_link" rel="noopener noreferrer">Entrevista com o Professor Luiz Antonio Tozi</a></p>
					<p className="centrodememoria-p2">
						&emsp;&emsp;Conheça mais o Prof. Tozi e  o que ele pensa da Fatec SJC
					</p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" target="_blank" href="https://drive.google.com/file/d/160rf0G9JCLqXYyN3GmVudSvl9y9OdCfN/view?usp=drive_link" rel="noopener noreferrer">Entrevista com o Professor Fernando Masanori</a></p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" target="_blank" href="https://drive.google.com/file/d/1JRpYsQ_UirSIHnmq2_KMN9Mos92aeT9r/view?usp=sharing" rel="noopener noreferrer">Histórico Laboratório Asas Rotativas e Drones</a></p>
					<p className="centrodememoria-p2">
						&emsp;&emsp;Você sabia que na FATEC SJC temos um Laboratório de Asas Rotativas e Drones?
					</p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" target="_blank" href="https://drive.google.com/file/d/1ynG1--fTR5XDGVaK5ibVri1Q9bzcer9n/view?usp=sharing" rel="noopener noreferrer">Chegada da Aeronave Guri na Fatec</a></p>
					<p className="centrodememoria-p2">
						&emsp;&emsp;Temos nos nossos laboratórios uma aeronave Guri, modelo AMT 600, fabricada no Brasil pela empresa AEROMOT, situada em Porto Alegre, RS. Sabia mais sobre ela...
					</p>
				<p className="centrodememoria-subtitulo2">
					<a className="centrodememoria-a" target="_blank" href="https://drive.google.com/file/d/1ywmQxCu8_YJidnx6I6m2q7V33a8RZZsg/view?usp=sharing" rel="noopener noreferrer">EMB-120 na Fatec</a></p>
					<p className="centrodememoria-p2">
						&emsp;&emsp;Temos a estrutura de um EMB-120, Brasília, junto ao nosso estacionamento.<br />
						&emsp;&emsp;Veja também um <a className="centrodememoria-a2" target="_blank" href="https://drive.google.com/file/d/1Qk2NJhMlAQKWnL7zNqLV9z2hIFEARYe2/view?usp=sharing" rel="noopener noreferrer">resumo da apresentação</a> feita neste semestre (2024-1) por alunos do 2. MNT mostrando como hoje está a "aeronave".
					</p>
			</Col>
		</Row>
	</Container>
	
	<Container style={{ marginBottom: "50px", textAlign: "justify" }}>
      <Row>
		<hr />
		<Col md={{ span: 12 }} style={{ marginBottom: "40px"}}>
          <p className="centrodememoria-subtitulo"  style={{ textAlign: "center", marginTop: "50px" }}>Memória Participativa</p>
		  <p className="centrodememoria-p2">
			A memória participativa é um conceito que valoriza a participação ativa de grupos, comunidades e coletivos culturais na preservação e promoção da memória social. Este site
			visa garantir que o direito à memória seja exercido democraticamente por diversos grupos de professores, alunos e funcionários.  Além disso, os inventários participativos
			trabalham na identificação e registro do patrimônio cultural e da memória social. A memória participativa é uma forma de aproximar pessoas, reaproximar comunidades
			e <b>fortalecer o sentimento de pertencimento e identidade</b>.
		  </p>
        </Col>
	  </Row>
	  <Row>
		<Col>
			<p className="centrodememoria-subtitulo2">História do Laboratório de Propulsores Aeronáuticos</p>
			<p className="centrodememoria-p2">
				A história do Laboratório de Propulsores foi apresentada na "Jornada Patrimônio Cultural da Educação Profissional e Tecnológica - Cultura Material e Práticas Escolares"
				realizada na sede do Centro Paula Souza em São Paulo nos dias 9 e 10 de  novembro de 2023 pelo Centros de Memória do Centro Paula
				Souza <a className="centrodememoria-a2" target="_blank" href="http://www.memorias.cpscetec.com.br/acervoVer.php?cma=29" rel="noopener noreferrer"> (http://www.memorias.cpscetec.com.br/acervoVer.php?cma=29)</a>. Leia o artigo completo neste <a className="centrodememoria-a2" target="_blank" href="https://drive.google.com/file/d/1ZBvb3Hmle4BaLtS8P046wl4SKZ03SJPc/view?usp=sharing" rel="noopener noreferrer">link</a>.<br /><br />
			</p>
			
			<p className="centrodememoria-subtitulo2">Catálogo dos Laboratórios de Aeronáutica</p>
			<p className="centrodememoria-p2">
				Os Catálogos dos Laboratórios de Aeronáutica foram organizados por alunos do Curso de Tecnologia em Manutenção de Aeronaves, baseado no conceito de Inventário Participativo,
				onde foram selecionados as partes mais significativas para o aprendizado no ponto de vista dos alunos.
			</p>
			<p className="centrodememoria-p2">
				A apresentação do Trabalho de Graduação dos alunos Anderson de Araújo Barroso e Kátia Aiko Nakamura do Curso de Tecnologia em manutenção de Aeronaves explica como estes
				catálogos estão sendo desenvolvidos. Veja neste <a className="centrodememoria-a2" target="_blank" href="https://drive.google.com/file/d/1UIHH2UHvNBdAmZkjOFiXaZhmu0SQS4yd/view" rel="noopener noreferrer">link</a>.
			</p>
			<p className="centrodememoria-p2">
				&emsp;&emsp;<a className="centrodememoria-a2" target="_blank" href="https://drive.google.com/file/d/1I_JlMfOYrTmcablq_3jG8cv9fE9XnYqa/view?usp=drive_link" rel="noopener noreferrer">Catálogo de Asas Rotativas</a>
			</p>
			<p className="centrodememoria-p2">
				&emsp;&emsp;<a className="centrodememoria-a2" target="_blank" href="https://drive.google.com/file/d/1HGOhL7bORI7zJgyWCMDNDVKlUXc529EK/view?usp=drive_link" rel="noopener noreferrer">Catálogo de Manutenção de Aeronaves</a>
			</p>
			<p className="centrodememoria-p2">
				&emsp;&emsp;<a className="centrodememoria-a2" target="_blank" href="https://drive.google.com/file/d/18ynfEVwQ4yeyNNCLlyySBQm90yZWwv2s/view?usp=drive_link" rel="noopener noreferrer">Catálogo de Metrologia</a>
			</p>
			<p className="centrodememoria-p2">
				&emsp;&emsp;<a className="centrodememoria-a2" target="_blank" href="https://drive.google.com/file/d/1rpHUElThmrMOsXKNB-txXvOWn4He9kvI/view?usp=sharing" rel="noopener noreferrer">Catálogo de Propulsores</a>
			</p>
		</Col>
	  </Row>
	</Container>

    <Container style={{ marginBottom: "50px", textAlign: "justify" }}>
      <Row>
        <hr />
        <Col md={{ span: 12 }} style={{ marginBottom: "40px" }}>
          <p id="centro_memoria" className="centrodememoria-subtitulo" style={{ marginTop: "50px" }}>
          Centro de Memória da Fatec de São José dos Campos “Professor Jessen Vidal”
          </p>
          
        <p className="centrodememoria-p">
          Criado pela Portaria 01 de 30 de junho de 2022, para promover o levantamento histórico desta Unidade de Ensino, preservar a memória; realizar o inventário do acervo; estimular e inter-relacionar atividades de instituição culturais no resgate da memória; estimular a consciência social na pesquisa, conservação do patrimônio da educação tecnológica; criar e manter o acervo permanente da memória da Fatec de São José dos Campos.
        </p>
        </Col>
		
        <p className="centrodememoria-p">
		<table>
          <tr>
            <td width="50%">Membros:</td> 
            <td>Gerson Carlos Favalli</td>
          </tr>
          <tr>
            <td></td>           
            <td>Luciana de Abreu Aquino</td>
          </tr>
		  <tr>
            <td></td>           
            <td>Sardes Aparecida Batista</td>
          </tr>
          <tr>
            <td></td>  
            <td>Nilo Jerônimo Vieira</td>    
          </tr>
          <tr>
            <td></td>  
            <td>Eliane Penha Mergulhão Dias</td>  
          </tr>
          <tr>
          <td></td> 
          <td></td> 
          </tr>
          <tr>
            <td>Professores convidados:</td>
            <td>Elisiane Alves de Oliveira</td>
            
            <td></td> 
          </tr>
          <tr>
            <td></td>
            <td>Julia Kanazawa (Etec Jacareí)</td>
          </tr>
        </table>
      </p>

      <p className="centrodememoria-p">
      Link relacionado: {" "}
      <a
              id="cor"
              target="_blank" rel="noopener noreferrer"
              href="http://www.memorias.cpscetec.com.br/acervoVer.php?cma=29"
            >
               http://www.memorias.cpscetec.com.br/acervoVer.php?cma=29
            </a>{" "}
      </p>
      </Row>
    </Container>
	
	<Container style={{ marginBottom: "50px", textAlign: "justify" }}>
      <Row>
        <hr />
        <Col md={{ span: 12 }} style={{ marginBottom: "40px" }}>
          <p id="jessen_vidal" className="centrodememoria-subtitulo" style={{ marginTop: "50px" }}>
			Quem foi Jessen Vidal?
          </p>

			<table>
				<tr>
					<td style={{verticalAlign: "top"}}><img width="200%" src={jessenvidal} alt="" /></td>
					<td>
						<p className="centrodememoria-p" style={{ marginLeft: "150px" }}>
							O Prof. Jessen Vidal nasceu no município pernambucano de Guaranhuns, em 21 de novembro de 1930, filho de Sólon Vidal e Katy Leitão Vidal. Na infância e adolescência frequentou o Colégio Presbiteriano XV de Novembro de Guaranhuns e em 1950 veio para São José dos Campos para estudar no Instituto Tecnológico de Aeronáutica (ITA).<br />
							Se formou Engenheiro Aeronáutico no ano de 1956, no ano de 1965, tirou o mestrado no ITA e em 1971 o doutorado na UNESP. Foi Professor Titular da Divisão de Aeronáutica do ITA, Professor Associado da Faculdade de Engenharia Mauá de 1965 a 1970. Reitor do ITA, de 1977 a 1982 e de 1989 a 1994 e Professor Titular e Diretor da Faculdade de Engenharia de Guaratinguetá, da UNESP.<br />
							Foi membro do Conselho Deliberativo da FATEC de 1979 a 1981 e do Conselho Estadual de Educação de 1981 a 1984 e Secretário da Educação do Estado de São Paulo entre 1982 e 1983. (Governo José Maria Marin). Foi também conselheiro do CREA entre 1967 a 1971 e participou do conselho da FUNCATE (Fundação de Projetos de Ciência, Aplicações e Tecnologia Espacial) no período de 1996 a 2007. Na vida pessoal, casou-se com Priscila de Macedo Custódio Vidal em 1958 e teve como filhos. Jessen Custodio Vidal Filho, Priscila Cristina Custódio Vidal Milioni e Ricardo Custódio Vidal. Faleceu em 12 de janeiro de 2009 em São José dos Campos, SP. Em 16 de dezembro de 2009 através da lei PL 379/2009 do deputado Hélio Nishimoto foi dado a denominação de “Professor Jessen Vidal” a Faculdade de Tecnologia de São José dos Campos (FATEC).<br />
						</p>
					</td>
				</tr>
			</table>
			
			<br />
			
			<p className="centrodememoria-p">Fontes:<br />
                <a
                id="cor"
                target="_blank" rel="noopener noreferrer"
                href="http://www.aeitaonline.com.br/wiki/index.php?title=Jessen_Vidal"
                >
                  http://www.aeitaonline.com.br/wiki/index.php?title=Jessen_Vidal
                </a>
                <br />
                <a
                id="cor"
                target="_blank" rel="noopener noreferrer"
                href="https://www.al.sp.gov.br/repositorio/legislacao/lei/2009/lei-13875-16.12.2009.html"
                >
                  https://www.al.sp.gov.br/repositorio/legislacao/lei/2009/lei-13875-16.12.2009.html
                </a>
                <br />
                <a
                id="cor"
                target="_blank" rel="noopener noreferrer"
                href="https://www.al.sp.gov.br/noticia/?id=262504"
                >
                  https://www.al.sp.gov.br/noticia/?id=262504
                </a>
			</p>

        </Col>
      </Row>
    </Container>

    <Container style={{ marginBottom: "50px", textAlign: "justify" }}>
      <Row>
        <hr />
        <Col md={{ span: 12 }} style={{ marginBottom: "40px" }}>
          <p id="breve_historico" className="centrodememoria-subtitulo" style={{ marginTop: "50px" }}>
          Um breve histórico
          </p>
          
        <p className="centrodememoria-p">
            A Faculdade de Tecnologia de São José dos Campos foi criada no dia 2 de março de 2006 conforme o Decreto Nº 50.580 de 2 de março de 2006 publicado no Diário Oficial. E inicialmente ficou instalada nos prédios do Parque Tecnológico São José dos Campos.
            <br />
            No 1º semestre de 2006, iniciou suas atividades com a implantação do Curso de Logística, com ênfase em Transportes.
            <br />
            No 1º semestre de 2007, foi implantado o curso de Tecnologia em Informática - ênfases em Banco de Dados e Redes de Computadores.
            <br />
            No 1º semestre de 2009, foi implantado o curso de Tecnologia em Sistemas Aeronáuticos - Manutenção e Manufatura Aeronáutica
            <br />
            Em maio de 2011 a Fatec saiu dos prédios do Parque Tecnológico São José dos Campos e passou a ocupar prédio próprio.
            <br />
            No 2º semestre de 2011, foram implantados os cursos de Tecnologia em Estruturas Leves e Automação Aeronáutica, dentro do eixo de Sistemas Aeronáuticos. Neste mesmo semestre foi também implantado no eixo de Informática, o curso de Tecnologia em Análise e Desenvolvimento de Sistemas (ADS).
            <br />
            No 1º semestre de 2013, foram extintos os cursos de Manufatura Aeronáutica e Automação Aeronáutica e em seus lugares foi implantado o curso de Tecnologia em Automação e Manufatura Digital. Também neste semestre o curso de Tecnologia em Estruturas Leves foi transformado em Tecnologia em Projetos de Estruturas Aeronáuticas (PEA) e foi implantado o curso de Tecnologia em Gestão de Produção Industrial (GPI) e o curso de Tecnologia em Banco de Dados e Redes de Computadores foi reestruturado e renomeado para Tecnologia em Banco de Dados.
            <br />
            No 1º semestre de 2015, foi implantado o curso de Tecnologia em Gestão Empresarial na modalidade de Ensino a Distância (EAD).
            <br />
            No 1º semestre de 2018 o curso de Tecnologia em Automação e Manufatura Digital foi transformado em Tecnologia em Manufatura Avançada e foi extinto o curso de Tecnologia em Gestão Empresarial.
            <br />
            A partir de abril de 2020 até fevereiro de 2022 todos os cursos foram ministrados de maneira remota pela Plataforma TEAMS em função da pandemia COVID-19.
            <br />
            No 1º semestre de 2021, retorna o curso de Tecnologia em Gestão Empresarial na modalidade de Ensino a Distância (EAD) e também foi implantado o curso de Tecnologia em Desenvolvimento de Software Multiplataforma.
            <p id="espaco"></p>
            <a
                id="cor"
                target="_blank" rel="noopener noreferrer"
                href="https://www.educaedu-brasil.com/centros/fatec--faculdade-de-tecnologia-de-sao-jose-dos-campos-uni2198"
                >
                  https://www.educaedu-brasil.com/centros/fatec--faculdade-de-tecnologia-de-sao-jose-dos-campos-uni2198
                </a>
        </p>
       </Col>  
      </Row>
      </Container>

      <Container style={{ marginBottom: "50px", textAlign: "justify" }}>
      <Row>
        <hr />
        <Col md={{ span: 12 }} style={{ marginBottom: "40px" }}>
          <p id="parque_tecnologico" className="centrodememoria-subtitulo" style={{ marginTop: "50px" }}>
          O Parque Tecnológico
          </p>
          
        <p className="centrodememoria-p">
            O Parque Tecnológico São José dos Campos foi instituído pelo Decreto Municipal nº 12.367/2006, de autoria do prefeito Eduardo Cury.
            <br />
            Entre 2006 e 2009 o Parque contou com uma estrutura provisória de gestão, sob responsabilidade conjugada da Fundação de Amparo à Pesquisa do Estado de São Paulo (Fapesp) e da Prefeitura de São José dos Campos.
            <br />
            Em 2009, passou a ser gerido pela Associação Parque Tecnológico de São José dos Campos (APTSJC), qualificada como Organização Social pela Prefeitura Municipal.
            <br />
            O planejamento macro para o PqTec foi organizado em três etapas: estruturação (2006-2009), consolidação (2010-2015) e expansão (2016-presente).
            <p id="espaco"></p>
        </p>

       </Col>  
      </Row>
      </Container>


    <Footer />
  </App>
);



export default centrodememoria;

