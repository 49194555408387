import React from "react";
import App from "../containers/App";
import Footer from "../components/footer";
import { Card, CardGroup, Container, Row, Col, Button } from "react-bootstrap";
import "./curso-projetos-de-estruturas-aeronauticas.css";
import ReactYouTube from "../components/youtube/ReactYoutube";

const introducao = [
  {
    titulo: "Resumo",
    descricao:
      "O PROJETISTA NÍVEL SUPERIOR DE ESTRUTURAS AERONÁUTICAS desenvolve em seus componentes temáticas transversais, competências profissionais e socioemocionais atuando no projeto e fabricação de partes, peças e estruturas do setor aeronáutico, indústria de máquinas, automotiva, petróleo e gás etc. utilizando materiais compósitos, metais ferroso e não ferrosos, termoplásticos, termofixos, etc.. No desenvolvimento dos projetos utiliza ferramentas modernas para concepção e gerenciamento deles com foco na aplicação da engenharia simultânea, mais especificamente utilizando o conceito, difundido internacionalmente, de “Projeto para Excelência” (Design for eXcellence - DfX). Conhece o vocabulário e os princípios básicos de funcionamento dos sistemas da aeronave, seus componentes e sua integração. Define os processos que serão utilizados na fabricação e montagem e elabora documentação técnica. Conhece os aspectos sociais, éticos e filosóficos desta área de atuação e as responsabilidades no meio social que integra com orientação constante para seguir normas de segurança, higiene e proteção ao meio-ambiente.",
  },
  {
    titulo: "Onde este tecnólogo poderá trabalhar?",
    descricao:
      "O mercado de projetista com nível superior é muito promissor e a demanda de vagas costuma ser superior ao número de profissionais disponíveis. Estes profissionais trabalham no setor aeronáutico como montadoras de aviões e fabricantes de peças e estruturas aeronáuticas, indústria automotiva e de máquinas, petróleo e gás, etc..",
  },
];

const links = [
  {
    titulo: "Inscreva-se",
    subtitulo: "40 Vagas > Período da Noite > Duração de 06 semestres",
    link: "https://vestibular.fatec.sp.gov.br/",
    botao: "Vestibular Fatec",
  },
  {
    titulo: "Docentes",
    subtitulo: "",
    link: "/docentes-pea",
    botao: "Clique aqui para visualizar a lista de docentes.",
  },
  {
    titulo: "Matriz Curricular anterior a 2024",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/cursos/pea/2016.11.16_matriz_pea.pdf",
    botao: "Clique aqui para visualizar a matriz curricular anterior a 2024 e a carga horária",
  },
  {
    titulo: "Matriz Curricular posterior a 2024",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/cursos/pea/Matriz curricular posterior a 2024.pdf",
    botao: "Clique aqui para visualizar a matriz curricular posterior a 2024 e a carga horária",
  },
  {
    titulo: "Projeto Pedagógico anterior a 2024",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/cursos/pea/projeto_pedagogico_pea.pdf",
    botao: "Clique aqui para visualizar o projeto pedagógico anterior a 2024 do curso.",
  },
  {
    titulo: "Projeto Pedagógico posterior a 2024",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/cursos/pea/PPC_Projetos de Estruturas Aeronáuticas_Fatec SJC_2024-1 reestruturação.pdf",
    botao: "Clique aqui para visualizar o projeto pedagógico posterior a 2024 do curso.",
  },
  {
    titulo: "Disciplinas Eleitas para Exame de Proficiência do Curso de Projetos de Estruturas Aeronáuticas",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/cursos/pea/Exame de Proficiência PEA.pdf",
    botao: "Clique aqui para visualizar as disciplinas eleitas",
  },
  {
    titulo: "Regulamento Geral dos Cursos de Graduação das Fatecs",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/arquivos/regulamento_geral_fatecs.pdf",
    botao: "Clique aqui para visualizar o Regulamento",
  },
  {
    titulo: "Regime Disciplinar Discente",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/arquivos/regime_disciplinar_discente.pdf",
    botao: "Clique aqui para visualizar o Regime Disciplinar Discente",
  },
];

const proj_est_aero = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col>
          <p className="pea-titulo">PROJETOS DE ESTRUTURAS AERONÁUTICAS</p>
          <p className="pea-subtitulo">
            40 Vagas no período da Noite | Controle e Processos Industriais
          </p>
        </Col>
      </Row>
    </Container>

    <Container id="resumo" style={{ marginBottom: "50px" }}>
      <Row>
        <Col md={{ span: 12 }}>
          <CardGroup>
            {introducao.map((object, index) => {
              return (
                <Card style={{ marginRight: "50px" }}>
                  <Card.Body>
                    <Card.Title className="pea-card-titulo">
                      {object.titulo}
                    </Card.Title>
                    <Card.Text className="pea-card-descricao">
                      {object.descricao}
                    </Card.Text>
                  </Card.Body>
                </Card>
              );
            })}
          </CardGroup>
        </Col>
      </Row>
    </Container>
	
    <Container style={{ display: "flex", justifyContent: "center" }}>
      <ReactYouTube videoId="bAaQiT6ESUs" />
      <br />
    </Container>
	<br /><br />
	
    <Container style={{ marginBottom: "50px", textAlign: "center" }}>
      <Row xs={1} md={2} className="g-4">
        {links.map((object, index) => {
          return (
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title className="pea-card-link-titulo">
                    {object.titulo}
                  </Card.Title>
                  <Card.Text className="pea-card-link-subtitulo">
                    {object.subtitulo}
                  </Card.Text>
                  <Button
                    className="pea-card-botao"
                    variant="outline-success"
                    href={object.link}
                    target="_blank"
                  >
                    {object.botao}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>

    <Footer />
  </App>
);

export default proj_est_aero;
