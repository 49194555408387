import React from "react";
import "./Menu.css";
import logo from "../images/logo.png";
import predio from "../images/predio.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "react-bootstrap/Image";

const Menu = () => {
  return (
    <div className="z-index-master">
      <Navbar className="color-nav" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand>FATEC SJC</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              
			  {/* HOME */}
			  <Nav.Link href="/">HOME</Nav.Link>
			  
			  {/* INSTITUCIONAL */}
              <NavDropdown title="INSTITUCIONAL" id="basic-nav-dropdown">
                <NavDropdown.Item href="/sobre">A Fatec</NavDropdown.Item>
                <NavDropdown.Item href="/achados-e-perdidos">Achados e Perdidos</NavDropdown.Item>
                <NavDropdown.Item href="/biblioteca">Biblioteca</NavDropdown.Item>
				<NavDropdown.Item href="/calendario-digital-de-eventos">Calendário Digital de Eventos</NavDropdown.Item>
				<NavDropdown.Item href="/centro-de-memoria">Centro de Memória</NavDropdown.Item>
                <NavDropdown.Item href="/cipa">CIPA</NavDropdown.Item>
                <NavDropdown.Item href="/concurso-publico-pss">Concurso Público e PSS</NavDropdown.Item>
				<NavDropdown.Item href="/corpo-administrativo">Corpo Administrativo</NavDropdown.Item>
                <NavDropdown.Item href="/cpa">CPA - Comissão Própria de Avaliação</NavDropdown.Item>
                <NavDropdown.Item href="/docentes">Docentes</NavDropdown.Item>
                <NavDropdown.Item href="/editais">Editais</NavDropdown.Item>
                <NavDropdown.Item href="/laboratorios-de-informatica">Laboratórios de Informática</NavDropdown.Item>
                <NavDropdown.Item href="/secretaria">Secretaria</NavDropdown.Item>
              </NavDropdown>
			  
			  {/* CURSOS */}
              <NavDropdown title="CURSOS" id="basic-nav-dropdown">
                <NavDropdown.Item href="/curso_ads">Análise e Desenvolvimento de Sistemas</NavDropdown.Item>
                <NavDropdown.Item href="/curso_bd">Banco de Dados</NavDropdown.Item>
                <NavDropdown.Item href="/curso_dsm">Desenvolvimento de Software Multiplataforma</NavDropdown.Item>
                <NavDropdown.Item href="/curso_gpi">Gestão da Produção Industrial</NavDropdown.Item>
				<NavDropdown.Item href="/curso_ge_ead">Gestão Empresarial (EaD)</NavDropdown.Item>
                <NavDropdown.Item href="/curso_log">Logística</NavDropdown.Item>
				<NavDropdown.Item href="/curso_mav">Manufatura Avançada</NavDropdown.Item>
                <NavDropdown.Item href="/curso_mnt">Manutenção de Aeronaves</NavDropdown.Item>
                <NavDropdown.Item href="/curso_pea">Projetos de Estruturas Aeronáuticas</NavDropdown.Item>
                <NavDropdown.Item href="/sobre_cursos">Sobre os cursos</NavDropdown.Item>
                <NavDropdown.Item href="https://vestibular.fatec.sp.gov.br/" target="_blank">Vestibular</NavDropdown.Item>
              </NavDropdown>
			  
			  {/* PROJETOS */}
              <NavDropdown title="PROJETOS" id="basic-nav-dropdown">
                <NavDropdown.Item href="/aerodesign">Aerodesign</NavDropdown.Item>
                <NavDropdown.Item href="https://inova.cps.sp.gov.br/" target="_blank">Agência Inova Paula Souza</NavDropdown.Item>
                <NavDropdown.Item href="/aprendizagem-por-projetos-integrados">Aprendizagem por Projetos Integrados</NavDropdown.Item>
                <NavDropdown.Item href="/baja">Baja</NavDropdown.Item>
				<NavDropdown.Item href="/cadi">CADI</NavDropdown.Item>
                <NavDropdown.Item href="http://www.cimatech.com.br" target="_blank">CIMATech</NavDropdown.Item>
                <NavDropdown.Item href="https://inova.cps.sp.gov.br/escola-de-inovadores/" target="_blank">Escola de Inovadores</NavDropdown.Item>
                <NavDropdown.Item href="/business-mentoring">Fatec Business Mentoring</NavDropdown.Item>
                <NavDropdown.Item href="https://nrifatec.wordpress.com" target="_blank">Núcleo de Relações Internacionais</NavDropdown.Item>
                <NavDropdown.Item href="/vestec">Vestec</NavDropdown.Item>
				<NavDropdown.Item href="/workshop">Workshop de Logística Humanitária</NavDropdown.Item>
              </NavDropdown>
			  
			  {/* AREA DO ALUNO */}
              <NavDropdown title="ÁREA DO ALUNO" id="basic-nav-dropdown">
				<NavDropdown.Item href="/calendario-academico">Calendário Acadêmico</NavDropdown.Item>
				<NavDropdown.Item href="/catalogo-de-tg">Catálogo de TG</NavDropdown.Item>
                <NavDropdown.Item href="/email-institucional">Email Institucional</NavDropdown.Item>
                <NavDropdown.Item href="/horarios-de-aula">Horários de Aula</NavDropdown.Item>
                <NavDropdown.Item href="/horarios-de-onibus">Horários de Ônibus</NavDropdown.Item>
                <NavDropdown.Item href="/monitoria">Monitoria</NavDropdown.Item>
                <NavDropdown.Item href="/normas-para-tg">Normas para TG</NavDropdown.Item>
                <NavDropdown.Item href="/passe-escolar">Passe Escolar</NavDropdown.Item>
                <NavDropdown.Item href="/portal-de-carreiras">Portal de Carreiras</NavDropdown.Item>
				{/*<NavDropdown.Item href="/rematricula">Rematrícula</NavDropdown.Item>*/}
                <NavDropdown.Item href="/setor-de-estagios">Setor de Estágios</NavDropdown.Item>
              </NavDropdown>
			  
			  {/* ACESSAR */}
              <NavDropdown title="ACESSAR" id="basic-nav-dropdown">
                <NavDropdown.Item href="https://siga.cps.sp.gov.br/aluno/login.aspx" target="_blank">SIGA (Alunos)</NavDropdown.Item>
                <NavDropdown.Item href="https://siga.cps.sp.gov.br/fatec/login.aspx" target="_blank">SIGA (Docentes)</NavDropdown.Item>
                <NavDropdown.Item href="https://login.microsoftonline.com/" target="_blank">Email Institucional</NavDropdown.Item>
                <NavDropdown.Item href="https://teams.microsoft.com/" target="_blank">Teams</NavDropdown.Item>
				<NavDropdown.Item href="https://sjc.fatec.sp.gov.br/downloads/arquivos/Tutorial%20de%20Utiliza%C3%A7%C3%A3o%20-%20Persuance.pdf" target="_blank">Persuance</NavDropdown.Item>
				<NavDropdown.Item href="https://di.cps.sp.gov.br/parceria-educacional/" target="_blank">Portal Parceria Educacional</NavDropdown.Item>
              </NavDropdown>
            
			</Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
	  
      <Container id="centro">
        <Image id="predio" src={predio} />
      </Container>
	  
      <Container id="submenu">
        <Row>
          <Col>
            <a
              id="estilo"
              target="_blank" rel="noopener noreferrer"
              href="https://www.cps.sp.gov.br/fatec/vestibular/"
            >
              VESTIBULAR
            </a>
          </Col>
          <Col>
            <a id="estilo" href="/secretaria">
              SECRETARIA
            </a>
          </Col>
          <Col>
            <a id="estilo" href="/portal-de-carreiras">
              PORTAL DE CARREIRAS
            </a>
          </Col>
          <Col>
            <a id="estilo" href="/contato">
              CONTATO
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Menu;
