import React from "react";
import App from "../containers/App";
import Footer from "../components/footer";
import { Card, CardGroup, Container, Row, Col, Button } from "react-bootstrap";
import "./curso-desenvolvimento-de-software-multiplataforma.css";

const introducao = [
  {
    titulo: "Resumo",
    descricao:
      "O Curso Superior Tecnológico em Desenvolvimento de Software Multiplataforma tem como objetivo formar profissionais capazes de desenvolver software para diversas plataformas, tais como Web, Desktop, Móvel, em Nuvem Internet das Coisas, empregando conceitos de Segurança da Informação e Inteligência Artificial. Assim como especializar profissionais para trabalhar com metodologias ágeis de gestão de projetos, versionamento, integração e entrega contínua de software, visando desenvolver soluções de software que atendam os critérios de qualidade exigidos pelo mercado. Além disso, pretende-se preparar os egressos para estabelecer relacionamentos produtivos; desenvolver a capacidade de comunicação, inclusive em língua estrangeira; utilizar raciocínio lógico; gerar soluções inovadoras; saber posicionar-se enquanto profissional e cidadão ético, com responsabilidade social e ambiental.",
  },
  {
    titulo: "Onde este tecnólogo poderá trabalhar?",
    descricao:
      "O egresso do Curso Superior Tecnológico em Desenvolvimento de Software Multiplataforma poderá atuar em grandes empresas, como especialista ou gestor; em empresas menores, com perfil de atuação mais generalista; por conta própria, no modelo autônomo, bem como empreender e criar sua própria empresa. Organizações não-governamentais. Órgãos públicos. Institutos e Centros de Pesquisa.",
  },
];

const links = [
  {
    titulo: "Inscreva-se",
    subtitulo: "40 Vagas > Período da Manhã > Duração de 06 semestres",
    link: "https://vestibular.fatec.sp.gov.br/",
    botao: "Vestibular Fatec",
  },
  {
    titulo: "Matriz curricular",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/cursos/dsm/2020_matriz_dsm.pdf",
    botao: "Clique aqui para visualizar a matriz curricular e a carga horária",
  },
  {
    titulo: "Projeto Pedagógico",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/cursos/dsm/PPC-DesenvolvimentoSoftwareMultiplataformaFatecSJCCesu-V1_2 2024-04-04-2.pdf",
    botao: "Clique aqui para visualizar o projeto pedagógico do curso",
  },
  {
    titulo: "Docentes",
    subtitulo: "",
    link: "/docentes-dsm",
    botao: "Clique aqui para visualizar a lista de docentes",
  },
  {
    titulo: "Regulamento Geral dos Cursos de Graduação das Fatecs",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/arquivos/regulamento_geral_fatecs.pdf",
    botao: "Clique aqui para visualizar o Regulamento",
  },
  {
    titulo: "Regime Disciplinar Discente",
    subtitulo: "",
    link: "https://sjc.fatec.sp.gov.br/downloads/arquivos/regime_disciplinar_discente.pdf",
    botao: "Clique aqui para visualizar o Regime Disciplinar Discente",
  },
];

const dsm = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col>
          <p className="dsm-titulo">
            DESENVOLVIMENTO DE SOFTWARE MULTIPLATAFORMA
          </p>
          <p className="dsm-subtitulo">
            40 Vagas no período da Manhã | Informação e Comunicação
          </p>
        </Col>
      </Row>
    </Container>

    <Container id="resumo" style={{ marginBottom: "50px" }}>
      <Row>
        <Col md={{ span: 12 }}>
          <CardGroup>
            {introducao.map((object, index) => {
              return (
                <Card style={{ marginRight: "50px" }}>
                  <Card.Body>
                    <Card.Title className="dsm-card-titulo">
                      {object.titulo}
                    </Card.Title>
                    <Card.Text className="dsm-card-descricao">
                      {object.descricao}
                    </Card.Text>
                  </Card.Body>
                </Card>
              );
            })}
          </CardGroup>
        </Col>
      </Row>
    </Container>
	
    <Container style={{ marginBottom: "50px", textAlign: "center" }}>
      <Row xs={1} md={2} className="g-4">
        {links.map((object, index) => {
          return (
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title className="dsm-card-link-titulo">
                    {object.titulo}
                  </Card.Title>
                  <Card.Text className="dsm-card-link-subtitulo">
                    {object.subtitulo}
                  </Card.Text>
                  <Button
                    className="dsm-card-botao"
                    variant="outline-success"
                    href={object.link}
                    target="_blank"
                  >
                    {object.botao}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>

    <Footer />
  </App>
);

export default dsm;
